<template>
  <div class="ProjectModal">
    <v-dialog
      v-model="show"
      hide-overlay
      transition="fade-transition"
      fullscreen
    >
      <v-card color="white" elevation="0">
        <div class="close-x" @click.stop="show = false">
          <v-img src="@/assets/icons/tacha.svg"></v-img>
        </div>

        <v-container class="py-0" fluid>
          <v-row>
            <v-col
              cols="12"
              md="4"
              class="pa-12 position-relative"
              order="1"
              order-md="0"
            >
              <v-row class="Project--row-height-mobile fill-height" no-gutters>
                <v-col cols="12" class="Project--line-height-texts">
                  <div
                    class="Project--paragraphs titles capitalize aeonik-bold color-black word-spaces"
                    v-html="projectGet.name"
                  ></div>
                  <div>
                    <span class="capitalize titles aeonik-light">{{
                      projectGet.location
                    }}</span>
                  </div>
                  <div>
                    <span class="titles aeonik-light"
                      >{{ numberWithCommas(projectGet.meters) }}m<sup
                        >2</sup
                      ></span
                    >
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  class="ProjectModal--counter-images mt-5 mt-md-0 pa-0 align-self-end"
                >
                  <span class="titles aeonik-light">
                    <template v-for="(asset, c) in projectAssetsGet">
                      <span
                        class="titles aeonik-light"
                        v-if="c == projectCarousel"
                        v-bind:key="c"
                      >
                        {{ c + 1 }}
                      </span>
                    </template>
                    de {{ projectAssetsGet.length }}
                  </span>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              md="8"
              class="pa-0 position-relative"
              order="0"
              order-md="1"
              id="project-carousel"
            >
              <v-carousel
                v-model="projectCarousel"
                v-bind:show-arrows="false"
                hide-delimiter-background
                hide-delimiters
                class="ProjectModal__carousel"
              >
                <v-carousel-item
                  v-for="(image, img) in projectAssetsGet"
                  v-bind:key="img"
                  transition="fade-transtion"
                  reverse-transition="fade-transition"
                  class="ProjectModal__carousel--item"
                >
                  <template v-if="isVideo(image.settings.image.path)">
                    <video
                      class="modalVideo"
                      autoplay
                      muted
                      style="object-fit: cover"
                      loop
                      :height="heightVideo"
                    >
                      <source
                        type="video/mp4"
                        :src="
                          'https://www.ofisdesigncms.actstudio.xyz' +
                          image.settings.image.path
                        "
                      />
                    </video>
                  </template>

                  <template v-else>
                    <v-img
                      lazy-src
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz' +
                        image.settings.image.path
                      "
                      class="ProjectModal--image"
                    ></v-img>
                  </template>
                </v-carousel-item>
              </v-carousel>

              <div
                class="transparent-activator-right in-modal white-space"
                @click="projectCarousel++"
              ></div>

              <div
                class="transparent-activator-x white-space"
                @click.stop="show = false"
              ></div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <span class="d-none">
      {{ getImages }}
    </span>
  </div>
</template>
<script>
export default {
  name: "ProjectModal",
  props: {
    visible: { type: Boolean, required: true },
    projectGet: { type: Object, required: true },
    projectAssetsGet: { type: Array, required: true },
    projectAssetPath: { type: String, required: true },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          return this.$emit("close");
        }
      },
    },
    getImages: function () {
      return (this.projectCarousel = this.projectAssetsGet.findIndex(
        (data) => data.settings.image.path == this.projectAssetPath
      ));
    },
  },
  data() {
    return {
      projectCarousel: 0,
      heightVideo: 0,
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    isVideo: function (path) {
      return path.substring(path.length - 4) == ".mp4";
    },
    onResize: function () {
      this.heightVideo = window.innerHeight;
    },
    numberWithCommas: function (x) {
      return (x || "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>